<template>
  <bg-modal
    :value="value"
    :close-on-click-backdrop="false"
    desktop-size="sm"
    :closable="false"
    class="loading-overlay"
    @close="hideLoading"
  >
    <div class="flex align-center justify-center loading-overlay__content">
      <div class="loading-overlay__loader"></div>
      <p class="loading-overlay__text" v-if="text">{{ text }}</p>
    </div>
  </bg-modal>
</template>

<script>
import { BgModal } from 'bangul-vue';

export default {
  name: 'LoadingOverlay',

  components: {
    BgModal,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
  },

  methods: {
    hideLoading() {
      this.$emit('update:value', false);
    },
  },
};
</script>

<style lang="scss" src="./LoadingOverlay.scss" scoped></style>
